<template>
  <v-container class="vh-footer ps-re lynn-mobile lynn-web" fluid>
    <v-container class="pd16">
      <ul class="my-self">
        <li>
          <v-img
            :src="require('@/assets/images/myself/myself01.png')"
            class="mb-vs"
            contain
            max-width="100%"
          ></v-img>
          <v-img
            :src="require('@/assets/images/myself/myself01-web.png')"
            class="web-vs"
            contain
            max-width="100%"
          ></v-img>
        </li>
        <li>
          <v-img
            :src="require('@/assets/images/myself/myself02.png')"
            class="mb-vs"
            contain
            max-width="100%"
          ></v-img>
          <v-img
            :src="require('@/assets/images/myself/myself02-web.png')"
            class="web-vs"
            contain
            max-width="100%"
          ></v-img>
        </li>
        <li>
          <v-img
            :src="require('@/assets/images/myself/myself03.png')"
            class="mb-vs"
            contain
            max-width="100%"
          ></v-img>
          <v-img
            :src="require('@/assets/images/myself/myself03-web.png')"
            class="web-vs"
            contain
            max-width="100%"
          ></v-img>
        </li>
        <li>
          <v-img
            :src="require('@/assets/images/myself/myself04.png')"
            class="mb-vs"
            contain
            max-width="100%"
          ></v-img>
        </li>
        <li class="black" v-if="is_mobile && !is_app">
          <div class="ms-text">
            <strong>앱 다운받고</strong>
            <p>스마트한 일상 시작</p>
          </div>
          <div class="ms-ap" v-if="is_ios">
            <a href="https://apps.apple.com/app/id1559671877"
              ><v-img
                :src="require('@/assets/images/myself/icon-apple.png')"
                contain
                max-width="100%"
              ></v-img
            ></a>
          </div>
          <div class="ms-go" v-if="is_android">
            <a
              href="https://play.google.com/store/apps/details?id=com.woomi.lynnlife"
              ><v-img
                :src="require('@/assets/images/myself/icon-google.png')"
                contain
                max-width="100%"
              ></v-img
            ></a>
          </div>
        </li>
        <li class="isMobile" v-if="!is_mobile && !is_app">
          ※편의 서비스, 스마트홈 기능은 단지에 따라 상이할 수 있습니다.
        </li>
      </ul>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "smartLynn",
  computed: {
    browserInfo() {
      return navigator.userAgent.toLowerCase();
    },
    is_android() {
      return this.browserInfo.indexOf("android") > 0;
    },
    is_ios() {
      return (
        this.browserInfo.indexOf("iphone") > 0 ||
        this.browserInfo.indexOf("ipad") > 0
      );
    },
    is_mobile() {
      return this.is_ios || this.is_android;
    },
    is_app() {
      return this.browserInfo.indexOf("wikey_flutter") > 0;
    },
  },
};
</script>
