import { AuthConnection, toQueries } from "./config.js";

export function checkSurvey(id, query) {
    let q = toQueries(query);
    return AuthConnection.get(
        `/survey/check/${id}?${q}`
    );
}

export function getSurvey(id, is_example) {
    return AuthConnection.get(
        `/survey/surveys/${id}?is_example=${is_example}`
    );
}

export function getSurveyEample(id, is_example) {
    return AuthConnection.get(
        `/survey/surveys-example/${id}`
    );
}

export function postSurveyResponse(id, data) {
    return AuthConnection.post(`/survey/surveys/${id}/responses`, data);
}